
.dataTables_wrapper .dataTables_info{
    /* transform: translate(-114px, 10px) !important; */
    /* transform: translateX(-15px) !important; */

}
.jodit-status-bar a.jodit-status-bar-link{
  display: none !important;
}

.pagination{
    padding: 10px !important;
}
td, th {
    border: 1px solid #dddddd;
    text-align: center !important;
    padding: 20px !important;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }
  input.inputBox {
    width:200px;
    font-size: 15px;
    padding: 5px 8px 4px 8px;
    border-radius: 3px;
    border: 1px solid #666;
  }
  /* .calendarWrap {
    display: inline-block;
    position: absolute;
    z-index: 9999;
    left: 17rem;
    top: 13.2%;
  } */

.dropdown-toggle {
  border: 1px solid #d8dbe0 !important;
  width: 197px !important;
  font-size: 17px !important;
  color: #d8dbe0;

}
.dropdown-toggle::after{
  margin-left: 5.255em !important;
}
.dropdown-menu{
  min-width: 12rem !important;
}
.dropdown-item.active, .dropdown-item:active{
  background-color: white !important;
  color: black !important;

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.card-header:first-child {

  color: #9c2ba1
}



