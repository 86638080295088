// todo: disabled button styles
button {
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}

// todo: brand button icon margin
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #2eb85c;
    border-color: #2eb85c;
}

.c-sidebar {
    color: #fff;
    background: #252525;
}
a {
    text-decoration: none;
    background-color: transparent;
    // color: #9c2b9f ;
}
.c-icon {
    /* display: inline-block; */
    /* color: inherit; */
    /* text-align: center; */
    /* fill: currentColor; */
    display: none;
}
.btn-primary{
  background-color: #252525 !important;
  border-color: #252525 !important;

}
.page-item.active .page-link {
  background-color: #252525 !important;
  border-color: #252525 !important;
  color: #fff !important;
  
}
.page-link{
  border-color: #252525 !important;
  color: black !important;

}
.list-group-item.active{
  background-color: #252525 !important;
  border-color: #252525 !important;
  color: #fff !important;

}
.btn:focus{
  box-shadow: none !important;
}
.modal-success .modal-content{
  border-color: #252525 !important;

}
.modal-success .modal-header{
  background-color: #252525 !important;

}
.btn-success{
  background-color: #252525 !important;
  border-color: #252525 !important;
  outline: none !important;

}
@media (hover: hover), (-ms-high-contrast: none){
.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    color: black !important;
    background: #dedde5 !important;
}
}
.c-icon {
    display: inline-block;
    color: inherit;
    text-align: center;
    fill: currentColor;
    display: none;
}


.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #2eb85c;
    border-color: #2eb85c;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    line-height: 1.25;
    border: 1px solid #2eb85c;
    color: rgb(46, 184, 92);
    background-color: rgb(255, 255, 255);
}